import React from "react"

import categoryPattern from "../../images/pattern-categoria.svg"

function Category( {category} ) {

  return (
    <section className="h-32 md:h-44 flex flex-col justify-end mt-16 xl:mt-20 mb-6 py-4 bg-greenQS" style={{backgroundImage: `url(${categoryPattern})`}}>

      <h1 className="text-white text-2xl text-center font-bold">{category}</h1>

    </section>
  )
}

export default Category