import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import PostsLayout from "../components/postsLayout"
import Category from "../components/underHeader/category"

function BlogCategory({ data, pageContext: { nextPagePath, previousPagePath, category, categoryUri } }) {

  const posts = data.allWpPost.nodes

  return (
    <Layout>

      <Seo title={category} />

      <Category category={category} />

      <PostsLayout posts={posts} nextPagePath={nextPagePath} previousPagePath={previousPagePath} uri={categoryUri} />

    </Layout>
  )
}

export default BlogCategory

export const pageQuery = graphql`
  query WordPressBlogCategoryArchive($offset: Int!, $postsPerPage: Int!, $categoryID: String!) {
    allWpPost(
      filter: {categories: {nodes: {elemMatch: {id: {in: [$categoryID]}}}}}
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        uri
        date(formatString: "DD/MM/YYYY")
        title
        categories {
          nodes {
            id
            name
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 400,
                  placeholder: BLURRED,
                  formats: [WEBP],
                  quality: 100
                )
              }
            }
          }
        }
      }
    }
  }
`
